const france = {
  key: "france",
  canonicalUrl: "www.france.fr",
  datalayerId: "GTM-N7FRS7X",
  customerId: 680,
  icon: "france.ico",
  headerLogo: "france.png",
  footerLogo: "otc_monochrome.png",
  axeptioClientId: "",
  axeptioCookiesVersion: "france.fr",
  clientUrl: "https://www.france.fr/",
  proUrl: "https://www.france.fr/",
  languageSelector: true,
  sanity: {
    base: {
      projectId: "aq3inzte",
      dataset: "production",
      apiVersion: "2022-12-01",
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token:
        "sklGMRplPfWUS1EzSuWPh95eXZktqV85Mx3cH8bZhXsubSmgzkmeF06LwCHuNHyR9XaQVO33dQKOUsx5mTMNIGabxnBJbO2c6Bs822WD9HjIFWqEC3k9wigKAHyTp7glQksnQ3fD8lJZZghqIe4nlKQA5T0Niqb70BQ8ZRaV9kC5QxonZuFO",
    },
  },
  dev: {
    clientUrl: "https://www.france.fr/",
    proUrl: "https://www.france.fr/",
  },
  travelForceLogin: false,
};

export default france;
