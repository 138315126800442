import React, { useState, useEffect } from "react";
import { useTranslation } from "../../tools/i18n";
import {Picto} from "../Picto";
import StaticCarousel from "../Carousel/StaticCarousel";
import { fetchArticlesData } from "../../tools/apiTools";

interface Article {
    title: string;
    url: string;
    image_url: string;
    category: string;
    location: string;
}

interface ArticlesProps {
    requirements: any;
    cityKey: string;
}

const removeDuplicateArticles = (articles: Article[]): Article[] => {
    const uniqueTitles = new Set();
    return articles.filter(article => {
        if (uniqueTitles.has(article.title)) {
            return false;
        }
        uniqueTitles.add(article.title);
        return true;
    });
};

const Articles: React.FC<ArticlesProps> = ({ requirements, cityKey }) => {
    const { t } = useTranslation();
    const [articles, setArticles] = useState<Article[]>([]);
    const API_KEY = process.env.REACT_APP_FRANCE_API_KEY;
    const SECRET_KEY: string = process.env.REACT_APP_FRANCE_SECRET_KEY;

    // For testing purposes for now -> translations to come using i18n and labels
    const sanitizeTitle = (title: string) => {
        return title
            .replace(/\d+/g, "") // General replacement
            .replace(/Nature\/Slow\/Autre/gi, "Slow Travel") // Specific replacement
            .replace(/Check-list/gi, "Essentiels"); // Specific replacement
    }

    useEffect(() => {
        fetchArticlesData(cityKey, API_KEY, SECRET_KEY)
            .then(data => {
                if(data) {
                    const mappedArticles = data.map((article, index) => ({
                        key: `article_${index}`,
                        title: article.title,
                        image: article.image_url,
                        noNote: true,
                        url: article.url,
                        category: sanitizeTitle(article.category),
                        place: article.location,
                    }));
                    const uniqueArticles = removeDuplicateArticles(mappedArticles);
                    setArticles(uniqueArticles);
                }
            }).catch(e => console.error(e));
    }, [cityKey]);

    return (
        <>
            {articles.length > 0 ? (
                <div className="Articles">
                    <div className="articles-header">
                        <Picto iconKey={"voyages"} width="28" height="28" />
                        <span>{t('travelPlaner.Travel.articles.title')}</span>
                    </div>
                    <StaticCarousel
                        label=""
                        subLabel=""
                        slides={articles}
                        requirements={requirements}
                    />
                </div>
            ) : null}
        </>
    );
};

export default Articles;
